
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

const hashTag = () => {
  const ret: { [k: string]: string } = {};
  if (!window.location.hash) {
    return ret;
  }
  const pos = window.location.hash.indexOf("?");
  if (pos == -1) {
    return ret;
  }
  const qs = window.location.hash.substring(pos + 1);
  const ps: string[] = qs.split("&");
  for (let i = 0; i < ps.length; i++) {
    const p = ps[i];
    if (!p) continue;
    const [k, v] = p.split("=");
    if (k && v !== undefined) {
      ret[k] = decodeURIComponent(v);
    }
  }
  return ret;
};

@Options({
  components: {
    HelloWorld,
  },
})
export default class HomeView extends Vue {
  appList: any[] = [];
  get hashTag(): { [k: string]: string } {
    return hashTag();
  }
  mounted() {
    // const token = 'ZZtolppub2hoY2ebap2ebp+aaZ5rb2heZGVml5uXmWCHnpLf24tpe5J3pdKjsLyf4Myomn+HZ5ildnfAy9XfZHvck8C0b3yah4Wsog==';
    console.log(this.hashTag);
    const token = this.hashTag["token"];
    if (token && token.length > 0) {
      fetch("/philippines_loan/tloan/spread_app", {
        headers: new Headers({
          "Content-Type": "application/json",
          token: token,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          const { app_name } = this.hashTag;
          this.appList = res.ret.filter((e: any) => e.app_name != app_name);
        });
    } else {
      window.location.href = 'https://primaloan.xyz/login-phi-app'
    }
  }
}
